<template>
  <div>
    <FiltriImportazioni
      @getImportazioniList="searchImportazioniList"
      @resetFilters="resetFilters"
    />
    <TableImportazioni
      @getImportazioniList="getImportazioniList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
  onUnmounted,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FiltriImportazioni from "@/components/components-fit/importazioni/FiltriImportazioni.vue";
import TableImportazioni from "@/components/components-fit/importazioni/TableImportazioni.vue";
import { getList } from "@/requests/esportazioniRequests";

export default defineComponent({
  name: "Importazioni",
  components: {
    FiltriImportazioni,
    TableImportazioni,
  },
  props: {
    campiLocked: {
      type: Array,
      required: false,
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Importazioni");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const id_tipologia_richiesta = computed(
      () => store.getters.idTipologiaImportazioni
    );
    const data_importazioni_da = computed(
      () => store.getters.dataImportazioniDaSelected
    );
    const data_importazioni_a = computed(
      () => store.getters.dataImportazioniASelected
    );
    const rowsToSkip = computed(() => store.getters.rowsToSkipImportazioni);
    const fetchRows = computed(() => store.getters.fetchRowsImportazioni);
    /* const sortColumn = computed(() => store.getters.sortColumnImportazioni);
    const sortOrder = computed(() => store.getters.sortOrderImportazioni); */

    const getImportazioniList = async () => {
      await store.dispatch("setStoreListData", {
        keys: {
          id_Tipologia_Richiesta: id_tipologia_richiesta.value,
          data_Importazioni_Da: data_importazioni_da.value,
          data_Importazioni_A: data_importazioni_a.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
        },
        apiLink: globalApi.IMPORTAZIONI_LIST,
        itemName: "importazioni_list",
      });
    };

    const colorStatus = ref([]);
    const getImportazioniListNoStore = async (apiLink, params) => {
      await getList(apiLink, params).then((res) => {
        res.data.results.forEach((newArrEl, index) => {
          // confronto il colore sia nell'array attuale, sia in quello appena preso
          // se diverso, sostituisco l'array vecchio con quello nuovo
          if (
            newArrEl.colore != importazioni_list.value[index].colore ||
            newArrEl.percentuale_avanzamento !=
              importazioni_list.value[index].percentuale_avanzamento
          ) {
            store.commit("spliceArrayFromName", "resultsimportazioni_list");
            store.commit("populateArrayFromName", {
              stateName: "resultsimportazioni_list",
              array: res.data.results,
            });
          }
        });
      });
    };

    const importazioni_list = computed(() =>
      store.getters.getStateFromName("resultsimportazioni_list")
    );
    const pendingDoc = ref(false);
    const intervalCheckList = ref(null);

    intervalCheckList.value = setInterval(async () => checkPendingDoc(), 5000);

    const checkPendingDoc = async () => {
      // se id è 1 o 2 vuol dire che ci sono documenti in importazione
      pendingDoc.value = importazioni_list.value
        ? importazioni_list.value.some((element) => {
            return (
              element.id_stato_richiesta != 3 && element.id_stato_richiesta != 4
            );
          })
        : false;

      if (pendingDoc.value) {
        // se ci sono doc in espo, chiama la stessa api, con gli stessi parametri, ma senza aggiornare la lista
        await getImportazioniListNoStore(globalApi.IMPORTAZIONI_LIST, {
          id_Tipologia_Richiesta: id_tipologia_richiesta.value,
          data_Importazioni_Da: data_importazioni_da.value,
          data_Importazioni_A: data_importazioni_a.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
        });
      } else {
        clearInterval(intervalCheckList.value);
        return;
      }
    };

    const resetFilters = async () => {
      store.commit("resetFiltersImportazioni");
      await getImportazioniList();
    };
    getImportazioniList();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedimportazioni_list")
    );
    const searchImportazioniList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipImportazioni");
      getImportazioniList();
    };

    onUnmounted(() => {
      clearInterval(intervalCheckList.value);
    });

    return {
      getImportazioniList,
      resetFilters,
      searchImportazioniList,
      pendingDoc,
      colorStatus,
    };
  },
});
</script>
