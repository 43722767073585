<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div>
      <div class="row py-4">
        <!--   <div class="col-4">
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#modal_upload_batch"
          >
            <i class="bi bi-cloud-upload fs-6 text-gray-700"></i>
            Batch tessere estive
          </button>
        </div>

        <div class="col-4 text-center">
          <div>
            <button
              type="button"
              class="btn btn-light text-gray-700 fs-6 text-start"
              data-bs-toggle="modal"
              data-bs-target="#modal_upload_batch_sat"
            >
              <i class="bi bi-cloud-upload fs-6 text-gray-700"></i>
              Batch tessere Non Ago/SAT
            </button>
          </div>
        </div>
        <div class="col-4 text-end">
          <div>
            <button
              type="button"
              class="btn btn-light text-gray-700 fs-6 text-start"
              data-bs-toggle="modal"
              data-bs-target="#modal_upload_batch_rdc"
            >
              <i class="bi bi-cloud-upload fs-6 text-gray-700"></i>
              Batch tessere RDC
            </button>
          </div>
        </div> -->
      </div>
    </div>
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        importazioni_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="importazioni_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        :pushTo="`/importazioni/dettaglio-importazione/`"
        nameIdToPush="guid"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsImportazioni"
        @current-change="setCurrentPageImportazioni"
      >
        <template v-slot:cell-stato="{ row: data }"
          ><h1
            class="badge"
            :class="
              data.colore == 'blue'
                ? 'badge-light-primary'
                : data.colore == 'orange'
                ? 'badge-light-warning'
                : data.colore == 'green'
                ? 'badge-light-success'
                : data.colore == 'red'
                ? 'badge-light-danger'
                : data.colore == 'black'
                ? 'badge badge-light '
                : ''
            "
          >
            {{ data.stato }}
          </h1>
        </template>
        <template v-slot:cell-anno="{ row: data }">{{ data.anno }}</template>
        <template v-slot:cell-denominazione="{ row: data }"
          ><div class="tab-long">
            {{ data.denominazione }} ({{ data.cod_affiliazione }})
          </div></template
        >
        <template v-slot:cell-tipologia="{ row: data }">{{
          data.tipologia
        }}</template>
        <template v-slot:cell-data_ora_richiesta="{ row: data }"
          ><div class="text-center">
            {{ data.data_ora_richiesta }}
          </div></template
        >
        <template v-slot:cell-data_ora_termine_elaborazione="{ row: data }"
          ><div class="text-center">
            {{ data.data_ora_termine_elaborazione }}
          </div></template
        >
        <template v-slot:cell-percentuale_avanzamento="{ row: data }"
          ><div v-if="data.percentuale_avanzamento" class="text-center fw-bold">
            {{ data.percentuale_avanzamento }} %
          </div>
          <div class="text-center fw-bold" v-else>-</div></template
        >
        <template v-slot:cell-num_tessere_ok="{ row: data }"
          ><div class="text-center text-success fw-bold">
            {{ data.num_tessere_ok }}
          </div></template
        >
        <template v-slot:cell-num_tessere_ko="{ row: data }"
          ><div class="text-center text-danger fw-bold">
            {{ data.num_tessere_ko }}
          </div></template
        >
        <template v-slot:cell-options="{ row: data }">
          {{ data.options }}
          <div>
            <i class="bi bi-eye text-dark fs-4"></i></div
        ></template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import Datatable from "../../kt-datatable/KTDatatable.vue";

//import { deleteImportazioni } from "@/requests/esportazioniRequests";

import Loading from "vue3-loading-overlay";
import isEnabled from "@/composables/isEnabled.js";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "table-importazioni",
  components: {
    Datatable,
    Loading,
  },
  emits: ["getImportazioniList", "resetFilters"],
  props: {
    campiLocked: {
      type: Array,
      required: false,
    },
    societa: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const isLoading = ref(false);

    /* const elimina = (guid) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'importazione verrà eliminata.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteImportazioni(guid).then((res) => {

            if (res.status == 200) {
              alertSuccess("Importazioni eliminata.");
              emit("getImportazioniList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    }; */

    const setFetchRowsImportazioni = (e) => {
      store.commit("setFetchRowsImportazioni", e);
      emit("getImportazioniList");
    };
    const setCurrentPageImportazioni = (page) => {
      store.commit("setCurrentPageImportazioni", page);
      emit("getImportazioniList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnImportazioni", columnName);
      store.commit("setSortOrderImportazioni", order);
      emit("getImportazioniList");
    };

    const tableHeader = ref([
      {
        name: "Stato",
        key: "stato",
        sortable: false,
      },
      {
        name: "Anno",
        key: "anno",
        sortable: false,
      },
      {
        name: "Società",
        key: "denominazione",
        sortable: false,
      },
      {
        name: "Tipologia",
        key: "tipologia",
        sortable: false,
      },
      {
        name: "Data richiesta",
        key: "data_ora_richiesta",
        sortable: false,
        align: "center",
      },
      {
        name: "Data termine",
        key: "data_ora_termine_elaborazione",
        sortable: false,
        align: "center",
      },
      {
        name: "%",
        key: "percentuale_avanzamento",
        sortable: false,
        align: "center",
      },
      {
        name: "Tessere OK",
        key: "num_tessere_ok",
        sortable: false,
        align: "center",
      },
      {
        name: "Tessere KO",
        key: "num_tessere_ko",
        sortable: false,
        align: "center",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    return {
      importazioni_list: computed(() =>
        store.getters.getStateFromName("resultsimportazioni_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedimportazioni_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordimportazioni_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusimportazioni_list")
      ),

      currentPage: computed(() => store.getters.currentPageImportazioni),
      rowsToSkip: computed(() => store.getters.rowsToSkipImportazioni),
      fetchRows: computed(() => store.getters.fetchRowsImportazioni),
      sortColumn: computed(() => store.getters.sortColumnImportazioni),
      sortOrder: computed(() => store.getters.sortOrderImportazioni),
      setFetchRowsImportazioni,
      setCurrentPageImportazioni,
      setSortOrderColumn,
      tableHeader,
      isEnabled,

      isLoading,
      //elimina,
    };
  },
};
</script>
