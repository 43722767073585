<template>
  <div>
    <div class="card shadow">
      <div class="p-4" @keyup.enter="$emit('getImportazioniList')">
        <div class="row">
          <div class="col-md-3">
            <SelectInput
              :options="richieste_tipologie_importazioni"
              name="statoImportazioniLookup"
              placeholder="Tipologia"
              :value="idTipologiaImportazioni"
              @changeSelect="setTipologiaImportazioni($event)"
            />
          </div>
          <div class="col-md-3">
            <Datepicker
              v-model="dataImportazioniDaSelected"
              @update:modelValue="setDataImportazioniDaSelected($event)"
              placeholder="Data importazione da"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
            />
          </div>
          <div class="col-md-3">
            <Datepicker
              v-model="dataImportazioniASelected"
              @update:modelValue="setDataImportazioniASelected($event)"
              placeholder="Data importazione a"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
            />
          </div>
          <div class="text-end col-md-3">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getImportazioniList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, watch, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

import "vue3-date-time-picker/dist/main.css";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";

export default defineComponent({
  name: "filtri-importazioni",
  emits: ["resetFilters", "getImportazioniList"],
  props: {
    campiLocked: {
      type: Array,
      required: true,
    },
  },
  components: {
    SelectInput,
    Datepicker,
  },
  setup() {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const richieste_tipologie_importazioni = computed(() =>
      store.getters.getStateFromName("richieste_tipologie_importazioni")
    );
    const keys = ref("it");
    if (!richieste_tipologie_importazioni.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const idTipologiaImportazioni = computed(
      () => store.getters.idTipologiaImportazioni
    );
    const setTipologiaImportazioni = (event) => {
      store.commit("setTipologiaImportazioni", event);
    };

    const dataImportazioniDaSelected = computed(
      () => store.getters.dataImportazioniDaSelected
    );
    const setDataImportazioniDaSelected = (event) => {
      store.commit("setDataImportazioniDaSelected", event);
    };

    const dataImportazioniASelected = computed(
      () => store.getters.dataImportazioniASelected
    );
    const setDataImportazioniASelected = (event) => {
      store.commit("setDataImportazioniASelected", event);
    };

    return {
      idTipologiaImportazioni,
      dataImportazioniASelected,
      dataImportazioniDaSelected,
      setTipologiaImportazioni,
      setDataImportazioniDaSelected,
      setDataImportazioniASelected,
      richieste_tipologie_importazioni,
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedimportazioni_list")
      ),
    };
  },
});
</script>
